import * as Constants from './Constants';
import { logger } from './logger';
import jslogger from './JSONLoggerConstants.json';

const parseUrl = window.location.origin.split("/")[2];
const enableLog = true;

export const getRoutingKey = () => {
    
    if(parseUrl && (parseUrl.startsWith("it") || parseUrl.startsWith("uat") || parseUrl.startsWith("aae") || parseUrl.startsWith("emp") || parseUrl.startsWith("perf1") || parseUrl.startsWith("preprod") || parseUrl.startsWith("pentest1"))){
        if(parseUrl.split("-").length > 1) {
            return parseUrl.split("-")[0];
        }else{
            return parseUrl.split(".")[0];
        }
    }else{
        return Constants.PROD_ROUTING_KEY;
    }

}

export const isNewSSOEnabled = (jwtBodyJson) => {
    const clientOrDestReq = jwtBodyJson.params.sso && jwtBodyJson.params.sso.clientOrDestReq;
    return clientOrDestReq && (clientOrDestReq.client || clientOrDestReq.dest)  ? true : false
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(base64);
};

export const  readSessionStorageItems = (jwtBodyJson, token) => {
  const storage = window.sessionStorage;
  if (!storage) return null;
  else {
      const userId = jwtBodyJson.sub.toLowerCase();
      storage.setItem('ts:deviceId:' + userId, jwtBodyJson.did);
      storage.setItem('ts:usertkn', token);
      storage.setItem('ts:appid', jwtBodyJson.aud);
      storage.setItem('ts:userid', userId);
      storage.setItem('ts:sessionId:' + userId, jwtBodyJson.sid);
      storage.setItem('AccessToken', jwtBodyJson.accesstoken);
      //storage.setItem('pilotflags', jwtBodyJson.pilotflags || jwtBodyJson.pilotFlags);
      storage.setItem('InteractionID', jwtBodyJson.InteractionId);
      //storage.setItem('isDIYPilot', jwtBodyJson.isDIYPilot);
      storage.setItem("appDTimers", JSON.stringify(window.appDTimers));
  }

  const length = storage.length;
  const params = [];
  for (let index = 0; index < length; ++index) {
      const key = storage.key(index);
      if (!key) continue;
      if (key.startsWith("ts:") || key.startsWith("currentSession")) {
          const value = storage.getItem(key);
          //replace <null> to null due to failure at server validation due to potential risk
          params.push({ Key: key, Value: value.replace(/"/g, '\\"').replace(/<null>/g, 'null') });
      }
  }
  return params;
}

export const getCookie = (name) => {
  const cookieArr = document.cookie.split(";");

  for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");

      if (name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
      }
  }
  return null;
}

export const getConfigEnv = () => {
    const envType = getEnvType();
    if( parseUrl && envType === Constants.IT_ENV){
        return `${Constants.IT_ENV}-`;
    }else if (envType === Constants.UAT_ENV || envType === Constants.UAT_AAE_ENV || envType === Constants.PERF_ENV || envType === Constants.PENTEST_ENV){
        return `${Constants.ALPHA}-`;
    }
    return '';
}

export const getAKValue = () => {

    const envType = getEnvType();
    if(parseUrl && envType === Constants.IT_ENV){
        return Constants.IT_AK_VALUE;
    }else if (envType === Constants.UAT_ENV || envType === Constants.UAT_AAE_ENV || envType === Constants.PERF_ENV || envType === Constants.PENTEST_ENV){
        return Constants.UAT_AK_VALUE;
    }
    return Constants.PROD_AK_VALUE;
}

export const getEnvType = () => {

    if(parseUrl && ( parseUrl.startsWith(Constants.IT_ENV) || parseUrl.startsWith(Constants.LOCALHOST_ENV))){
        return Constants.IT_ENV;
    }else if (parseUrl.startsWith(Constants.UAT_ENV) || parseUrl.startsWith(Constants.UAT_AAE_ENV)){
        return Constants.UAT_ENV;
    }else if (parseUrl.startsWith(Constants.PERF_ENV)){
        return Constants.PERF_ENV;
    }else if (parseUrl.startsWith(Constants.PENTEST_ENV)){
        return Constants.PENTEST_ENV;
    }else{
        return Constants.PROD_ENV;
    }
}

export const getConfigEASEnv = () => {
    const envType = getEnvType();
    if(parseUrl && envType === Constants.IT_ENV){
        return `${Constants.IT_ENV}-`;
    }else if (envType === Constants.UAT_ENV || envType === Constants.UAT_AAE_ENV || envType === Constants.PERF_ENV || envType === Constants.PENTEST_ENV){
        return `${Constants.UAT}-`;
    }
    return Constants.EMPTY_STRING;
}

export const getHARUrlPrefix = () => {
    return parseUrl && (parseUrl.startsWith(Constants.UAT_ENV) || parseUrl.startsWith(Constants.UAT_AAE_ENV) || parseUrl.startsWith(Constants.PERF_ENV) || parseUrl.startsWith(Constants.PENTEST_ENV)) ? `${Constants.UAT_ENV}-`: `${Constants.EMPTY_STRING}`;
}

export const checkPartnerSiteRedirection = () => {
    const redirectionUrlPrefix = getHARUrlPrefix();
    window.location.href = `https://${redirectionUrlPrefix}creditcard.usbank.com/onlineCard/login.do?theme=HDV&loc=08924`; 
}

export const unSupportedBrowser = () => {
    const browser = (function (agent) {
        agent = agent.toLowerCase();
        switch (true) {
        case agent.indexOf('firefox') > -1: return 'firefox';
        case agent.indexOf('mozilla') > -1: return 'mozilla';
        case agent.indexOf('applewebkit') > -1: return 'applewebkit';
        case agent.indexOf('mobile') > -1: return 'mobile';
        case agent.indexOf('samsungbrowser') > -1: return 'unSupported';
        case agent.indexOf('opera') > -1:
        case agent.indexOf('opr') > -1:
            return 'unSupported';
        case agent.indexOf('trident') > -1: return 'ie';
        case agent.indexOf('edge') > -1: return 'edge';
        case agent.indexOf('chrome') > -1: return 'Google Chrome or Chromium';
        case agent.indexOf('safari') > -1: return 'safari';
        default: return 'unSupported';
        }
    });
    const sBrowser = browser((typeof window !== 'undefined') ? window.navigator.userAgent.toLowerCase() : '');
    return (sBrowser === 'unSupported');
};

export const getChannelId = () => {
    return parseUrl && getEnvType() === Constants.PERF_ENV ? Constants.CHANNEL_PERF : Constants.CHANNEL_WEB;
}

export const getAppDKey = () => {
    const env = getEnvType();
    return parseUrl && (env === Constants.IT_ENV || env === Constants.UAT_ENV || env === Constants.UAT_AAE_ENV || env === Constants.PERF_ENV || env === Constants.PENTEST_ENV) ? Constants.APPD_KEY_NONPROD : Constants.APPD_KEY_PROD;
}

export const logInfo = (comp,event,action='',msg='') => {
    const arr = [];
    Object.keys(jslogger).forEach(function(key) {
        arr.push(jslogger[key]);
    });
    const arrLoggerDetails = arr[0][comp][event];
    enableLog && logger.info({
    // @ts-ignore
        eventSource: {
            businessCapability: arrLoggerDetails[Constants.LOGGER_BUSINESS_CAPABILITY],
            businessFunctionality: arrLoggerDetails[Constants.LOGGER_BUSINESS_FUNCTIONALITY],
            component: comp,
            stepName: arrLoggerDetails[Constants.LOGGER_STEPNAME],
        },
        eventStatus: arrLoggerDetails[Constants.LOGGER_EVENT_STATUS],
        eventAttributes: {
            action: action == '' ? arrLoggerDetails[Constants.LOGGER_ACTION] : action,
            message: msg,
            appId : 'RETAIL',
            appVersion : '1.0',
            qmSession : window ? window.sessionStorage.getItem('qmSession') : ''
        },
    });
}

export const logError = (comp,feature, featureDesc,error,msg,eStatus) => {
    enableLog && logger.error({
        eventSource: {
            businessCapability: feature,
            businessFunctionality: featureDesc,
            component: comp,
        },
        eventStatus: eStatus?eStatus :'ERROR',
        exception: {
            exceptionCode: error?._errorCode,
            dumpAnalysis: error,
            severity:  'medium',
            exceptionMessage: error?._message || msg,
        },
    });    
};

/** Identify Desktop or Mobile */
export const isMobile = () => {
    const match = window.matchMedia('(pointer:coarse)');
    return match && match.matches;
};

export const getenv = () => window?.location?.hostname?.split('-')[0];

export const updateCallsignSessionTimeStamp = () => {
    const sStorage = window.sessionStorage;
    const callSignSession = sStorage.getItem('cx');
    const parsedCallSignSession = JSON.parse(callSignSession);
    if (parsedCallSignSession) {
    const updateTimestamp = {
        time: Date.now()
    };
    const callSignInfo1 = {...parsedCallSignSession,...updateTimestamp}
    sessionStorage.setItem('cx', JSON.stringify(callSignInfo1));
    sessionStorage.setItem('cxbkp', JSON.stringify(callSignInfo1));
    }else{
        const backUpSession = sessionStorage.getItem('cxbkp');
        const parsedbkpSession = JSON.parse(backUpSession);
        if (parsedbkpSession) {
            const transactionInfo = {
                time: Date.now(),
            };
            const callSignInfo = { ...parsedbkpSession, ...transactionInfo };
            sessionStorage.setItem('cx', JSON.stringify(callSignInfo));
            sessionStorage.setItem('cxbkp', JSON.stringify(callSignInfo));
        }
    }
}

export const updateCallsignSession = (transactionID, sanfInfo) => {
    // correletion ID is passed as transactionID func not needed as of now
    // interaction id is passed as session ID
    const sStorage = window.sessionStorage;
    const sessionID = sStorage.getItem('InteractionID');
    const callSignSession = sStorage.getItem('cx');
    const parsedCallSignSession = JSON.parse(callSignSession);
    const transactionInfo = {
        mosc: sessionID,
        ggow: transactionID,
        time: Date.now(),
        sanf: sanfInfo,
    };
    const callSignInfo = {...parsedCallSignSession,...transactionInfo}
    sessionStorage.setItem('cx', JSON.stringify(callSignInfo));
};

export const getDotComCookie = (name) => {
    const cookie = {};
    document.cookie.split(';').forEach((el) => {
        const split = el.split('=');
        cookie[split[0].trim()] = split.slice(1).join('=');
    });
    return cookie[name];
};

export const updateOlbTimers = (appId,timerName) => {
    const olbTimersSession = sessionStorage.getItem('olbTimers');
    let parsedOlbTimersSession = olbTimersSession && JSON.parse(olbTimersSession);
    if(parsedOlbTimersSession) {
        if(!parsedOlbTimersSession[appId]) parsedOlbTimersSession[appId]={};
        parsedOlbTimersSession[appId][timerName] = new Date().getTime();
        sessionStorage.setItem('olbTimers',JSON.stringify(parsedOlbTimersSession));
    }
};
