import React from "react";

import LoginWidgetRWC from './LoginWidgetRWC';
import { v4 as uuidv4 } from 'uuid';

function App({redirect}) {
  return (
    <div id={`rwc-login-${uuidv4()}`}>
      <LoginWidgetRWC
        redirect={redirect}
      />
    </div>
  );
}

export default App;
