import r2wc from "@r2wc/react-to-web-component";

import App from './App';

const WebProducts = r2wc(App, {
  props: {
    redirect: "string"
  }
});

!customElements.get('auth-login-rwc') && customElements.define("auth-login-rwc", WebProducts);
