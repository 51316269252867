import Logger from '@usb-ui-tools/logger';

Logger.init({
    appId: 'react-auth-login',
    advanced: true,
    eventType: 'AUTH',
    eventSubType: 'LOGIN',
    currentSession:
        typeof window !== 'undefined'
            ? JSON.parse(sessionStorage.getItem('currentSession'))
            : null,
});

export const logger = Logger;
