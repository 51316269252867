export const PROD_OLB_DOMAIN = "https://onlinebanking.usbank.com";
export const EMP_OLB_DOMAIN = "https://emp-onlinebanking.usbank.com";
export const UAT_OLB_DOMAIN = "https://uat2-onlinebanking.usbank.com";
export const IT_OLB_DOMAIN = "https://it2-onlinebanking.usbank.com";

export const APPD_KEY_IT_UAT = "AD-AAB-ACD-CYX";
export const APPD_KEY_PROD = "AD-AAB-ACD-CWE";


/* DOTCOM constants START */
export const DOTCOM_APPD_KEY_IT_UAT = "AD-AAB-ACD-CYX";
export const DOTCOM_APPD_KEY_PROD = "AD-AAB-ACD-CWE";

export const DOTCOM_IT_AK_VALUE = "6DnwT84FiPjKdOHJlxKkfCFxAsx62J15";
export const DOTCOM_UAT_AK_VALUE = "xGQGjOTjVjd9qEuAQjnCxxGYiyF7yFOK";
export const DOTCOM_PROD_AK_VALUE = "lmGObVmU04tgtuY85pIvqMcovKNGo0m0";

export const DOTCOM_IT_ENV = 'it2';
export const DOTCOM_UAT_ENV = 'uat2';
export const DOTCOM_EMP_ENV = 'emp';
export const DOTCOM_PROD_ENV = 'Prod';
export const DOTCOM_OLB_PATH = 'onlinebanking.usbank.com';
export const UNDEFINED= 'undefined';
/* DOTCOM constants START */